import { render, staticRenderFns } from "./MessageListMessageModern.vue?vue&type=template&id=47eddb1e&scoped=true&functional=true&"
import script from "./MessageListMessageModern.vue?vue&type=script&lang=js&"
export * from "./MessageListMessageModern.vue?vue&type=script&lang=js&"
import style0 from "./MessageListMessageModern.vue?vue&type=style&index=0&id=47eddb1e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "47eddb1e",
  null
  
)

export default component.exports