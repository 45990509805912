"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = {
  props: ['filter', 'buffer', 'items', 'itemsPerPage'],
  data: function data() {
    return {
      // items: [
      //     { text: 'anick1', type: 'user' },
      //     { text: 'anick2', type: 'user' },
      //     { text: 'bnick3', type: 'user' },
      //     { text: 'cnick4' },
      //     { text: 'dnick5' },
      // ],
      selected_idx: 0
    };
  },
  computed: {
    itemLimits: function itemLimits() {
      var itemLimit = parseInt(this.itemsPerPage, 10) || 7;
      var halfLimit = (itemLimit - 1) / 2;
      return {
        all: itemLimit,
        backward: Math.floor(halfLimit) || 1,
        forward: Math.ceil(halfLimit) || 1
      };
    },
    filteredItems: function filteredItems() {
      var _context, _context2;
      var filterVal = ((0, _filter.default)(this) || '').toLowerCase();
      return (0, _sort.default)(_context = (0, _filter.default)(_context2 = this.items).call(_context2, function (item) {
        var s = false;
        if (item.text.toLowerCase().indexOf(filterVal) === 0) {
          s = true;
        }
        (item.alias || []).forEach(function (alias) {
          if (alias.toLowerCase().indexOf(filterVal) === 0) {
            s = true;
          }
        });
        return s;
      })).call(_context, Misc.strCompare);
    },
    filteredAndLimitedItems: function filteredAndLimitedItems() {
      var _context3,
        _this = this;
      return (0, _filter.default)(_context3 = this.filteredItems).call(_context3, function (item, itemIdx, items) {
        var numItems = items.length - 1;
        var idxFrom = _this.selected_idx - _this.itemLimits.backward;
        var idxTo = _this.selected_idx + _this.itemLimits.forward;
        var isInRange = false;

        // Adjust the number of items before and after the selected item
        // when we reach either end of the list.
        // If we don't do this then this:
        // * Item 1
        // * item 2
        // * Item 3
        // * Item 4
        // * Item 5 < selected
        // Can turn into this:
        // * Item 4
        // * Item 5 < selected
        if (idxFrom < 0) {
          idxTo += -idxFrom;
          idxFrom = 0;
        } else if (idxTo > numItems) {
          idxFrom -= idxTo - numItems;
          idxTo = numItems;
        }
        if (itemIdx >= idxFrom && itemIdx <= idxTo) {
          isInRange = true;
        }

        // Keep track of the pre-limited index for item selection
        item.idx = itemIdx;
        return isInRange;
      });
    },
    selectedItem: function selectedItem() {
      var item = this.filteredItems[this.selected_idx];
      return item || null;
    }
  },
  watch: {
    selected_idx: function selected_idx() {
      var _this2 = this;
      // nextTick() as the DOM hasn't updated yet
      this.$nextTick(function () {
        var el = _this2.$el.querySelector('.kiwi-autocomplete-item--selected');
        if (!el) {
          return;
        }
        _this2.$el.scrollTop = el.offsetTop - el.getBoundingClientRect().height * 2;
      });
      this.tempCurrentItem();
    },
    filter: function filter() {
      var numItems = this.filteredAndLimitedItems.length - 1;
      if (this.selected_idx > numItems) {
        this.selected_idx = numItems < 0 ? 0 : numItems;
      }
    }
  },
  mounted: function mounted() {
    this.tempCurrentItem();
  },
  methods: {
    handleOnKeyDown: function handleOnKeyDown(event) {
      var handled = false;
      var cancelKeyCodes = ['Enter',
      // return
      ' ',
      // space
      ';',
      // semi-colon
      ',',
      // comma
      '.' // period
      ];

      if (cancelKeyCodes.indexOf(event.key) > -1) {
        // If no item is selected (ie. on an empty list), leave the return key
        // to do its default action as if the autocomplete box isn't active.
        if (!this.selectedItem) {
          this.cancel();
        } else {
          this.selectCurrentItem();
          if (event.key === 'Enter') {
            event.preventDefault();
          }
          handled = true;
        }
      } else if (event.key === 'ArrowUp' || event.key === 'Tab' && event.shiftKey) {
        // Up or tab + shift
        if (this.selected_idx > 0) {
          this.selected_idx--;
        } else {
          // Wrap around to the end
          this.selected_idx = this.filteredItems.length - 1;
        }
        event.preventDefault();
        handled = true;
      } else if (event.key === 'ArrowDown' && !event.altKey || event.key === 'Tab') {
        // Down or tab
        if (this.selected_idx < this.filteredItems.length - 1) {
          this.selected_idx++;
        } else {
          // Wrap around to the start
          this.selected_idx = 0;
        }
        event.preventDefault();
        handled = true;
      } else if (event.key === 'Shift') {
        // shift
        handled = true;
      } else if (event.key === 'PageUp' || event.key === 'PageDown') {
        // pageUp || pageDown
        var maxIdx = this.filteredItems.length - 1;
        var limits = this.itemLimits;
        var jump = limits.all;

        // current position is within the first or last 3
        // correctly jump the right amount
        if (this.selected_idx <= limits.backward) {
          jump = limits.all + limits.backward - this.selected_idx;
        } else if (this.selected_idx >= maxIdx - limits.forward) {
          // the center point maybe offset if an even number of items is shown
          jump = limits.all + limits.forward - (maxIdx - this.selected_idx);
        }

        // backwards or forward
        if (event.key === 'PageUp') {
          this.selected_idx -= jump;
        } else {
          this.selected_idx += jump;
        }

        // ensure we are not out of bounds
        if (this.selected_idx > maxIdx) {
          this.selected_idx = maxIdx;
        } else if (this.selected_idx < 0) {
          this.selected_idx = 0;
        }
        handled = true;
      }
      return handled;
    },
    handleClick: function handleClick(item) {
      this.selected_idx = item.idx;
      this.selectCurrentItem();
      this.$emit('click', item.value || item.text, item);
    },
    openQuery: function openQuery(nick) {
      var buffer = this.$state.addBuffer(this.buffer.networkid, nick);
      this.$state.setActiveBuffer(buffer.networkid, buffer.name);
      this.cancel();
    },
    tempCurrentItem: function tempCurrentItem() {
      var item = this.selectedItem;
      if (!item) {
        return;
      }
      this.$emit('temp', item.value || item.text, item);
    },
    selectCurrentItem: function selectCurrentItem() {
      var item = this.selectedItem;
      var value = '';
      if (item) {
        value = item.value || item.text;
      }
      this.$emit('selected', value, item);
    },
    cancel: function cancel() {
      this.$emit('cancel');
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["AutoComplete"]
window._kiwi_exports.components.AutoComplete = exports.default ? exports.default : exports;
