"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('form', {
    staticClass: "u-form kiwi-channelbanlist",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-banlist-ban u-form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.banMask,
      expression: "banMask"
    }],
    staticClass: "u-input",
    attrs: {
      "type": "text",
      "placeholder": "*!*@*"
    },
    domProps: {
      "value": _vm.banMask
    },
    on: {
      "keydown": _vm.banKeyDown,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.banMask = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "u-button u-button-secondary",
    on: {
      "click": function click($event) {
        return _vm.addBan();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('bans_add_ban')) + " ")])]) : _vm._e(), _c('a', {
    staticClass: "kiwi-banlist-refresh",
    class: {
      'u-link': !_vm.is_refreshing && !_vm.clickUpdateTimeout
    },
    on: {
      "click": _vm.clickUpdateBanlist
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('bans_refresh')) + " ")]), _vm.banList.length > 0 ? _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table",
    class: {
      'kiwi-sidebar-settings-access-restricted': !_vm.areWeAnOp
    }
  }, [_c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }, [_vm._v(_vm._s(_vm.$t('bans_user')))]), _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }, [_vm._v(_vm._s(_vm.$t('bans_by')))]), _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }), _vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }) : _vm._e(), _vm._l(_vm.sortedBanList, function (ban) {
    return [_c('div', {
      key: 'mask' + ban.banned,
      staticClass: "kiwi-sidebar-settings-access-mask"
    }, [_vm._v(" " + _vm._s(_vm.displayMask(ban.banned)) + " ")]), _c('div', {
      key: 'who' + ban.banned,
      staticClass: "kiwi-sidebar-settings-access-who"
    }, [_vm._v(" " + _vm._s(ban.banned_by) + " ")]), _c('div', {
      key: 'when' + ban.banned,
      staticClass: "kiwi-sidebar-settings-access-when",
      attrs: {
        "title": new Date(ban.banned_at * 1000).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }
    }, [_vm._v(" " + _vm._s(new Date(ban.banned_at * 1000).toLocaleDateString({
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })) + " ")]), _vm.areWeAnOp ? _c('div', {
      key: 'actions' + ban.banned,
      staticClass: "kiwi-sidebar-settings-access-actions"
    }, [_c('i', {
      staticClass: "fa fa-trash",
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": function click($event) {
          return _vm.removeBan(ban.banned);
        }
      }
    })]) : _vm._e()];
  })], 2) : _vm.is_refreshing ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('bans_refreshing')) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('bans_nobody')) + " ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;