"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('svg', {
    staticStyle: {
      "position": "fixed",
      "width": "0",
      "height": "0"
    },
    attrs: {
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('clipPath', {
    attrs: {
      "id": "kiwi-avatar-clip"
    }
  }, [_c('circle', {
    attrs: {
      "r": "50",
      "cx": "50",
      "cy": "50"
    }
  })]), _c('mask', {
    attrs: {
      "id": "kiwi-avatar-mask"
    }
  }, [_c('rect', {
    attrs: {
      "width": "100",
      "height": "100",
      "fill": "#fff"
    }
  }), _c('circle', {
    attrs: {
      "r": "14",
      "cx": "50",
      "cy": "0",
      "transform": _vm.awayStatusTransform
    }
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;