"use strict";
'kiwi public';

/** @module */
var _typeof3 = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.connectionInfoFromConfig = connectionInfoFromConfig;
exports.dateIso = dateIso;
exports.dedotObject = dedotObject;
exports.extractBuffers = extractBuffers;
exports.extractURL = extractURL;
exports.hasUnmatchedTrailingBracket = hasUnmatchedTrailingBracket;
exports.makePluginObject = makePluginObject;
exports.matchUri = matchUri;
exports.mentionsNick = mentionsNick;
exports.networkErrorMessage = networkErrorMessage;
exports.parseIntZero = parseIntZero;
exports.parseIrcUri = parseIrcUri;
exports.parsePresetServer = parsePresetServer;
exports.queryStringVal = queryStringVal;
exports.replaceObjectProps = replaceObjectProps;
exports.strCompare = strCompare;
exports.stripStyles = stripStyles;
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));
var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _lodash = _interopRequireDefault(require("lodash"));
var _vue = _interopRequireDefault(require("vue"));
var _strftime = _interopRequireDefault(require("strftime"));
var _PluginWrapper = _interopRequireDefault(require("@/components/utils/PluginWrapper"));
var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));
var _TextFormatting2 = require("./TextFormatting");
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var strftimeUTC = _strftime.default.timezone('+0');
/**
 * Extract an array of buffers from a string, parsing multiple buffer names and channel keys
 * "#chan,#chan2" => 2 channels without a key
 * "#chan,#chan2 key" => 2 channels, the first having a key
 * "#chan,#chan2 key1,key2" => 2 channels, both having a key
 * "#chan,#chan2 ,key2" => 2 channels, the second having a key
 * @param {string} str List of buffer names and channel keys
 */
function extractBuffers(str) {
  var spaceIdx = str.indexOf(' ');
  if (spaceIdx === -1) spaceIdx = str.length;
  var bufferNames = str.substr(0, spaceIdx).split(',');
  var keys = str.substr(spaceIdx + 1).split(',');
  var buffers = [];
  bufferNames.forEach(function (bufferName, idx) {
    // return if bufferName is empty
    if (!(0, _trim.default)(bufferName).call(bufferName)) {
      return;
    }
    buffers.push({
      name: bufferName,
      key: keys[idx] || ''
    });
  });
  return buffers;
}
function extractURL(str) {
  var matches = str.match(_TextFormatting2.urlRegex);
  return matches ? matches[0] : '';
}
function stripStyles(str) {
  return str.replace(/(\x03[0-9]{0,2})?([\x02\x16\x1d\x1f]+)?/g, '');
}

/**
 * Does a string mention a nickname?
 * @param {string} input The string to search within
 * @param {string} nick The nick to search for
 */
function mentionsNick(input, nick) {
  var _context;
  if (input.toLowerCase().indexOf(nick.toLowerCase()) === -1) {
    return false;
  }
  var punc = '\\s,.!:;+()\\[\\]?¿\\/<>@-';
  var escapedNick = _lodash.default.escapeRegExp(nick);
  var stylesStrippedInput = stripStyles(input);
  var r = new RegExp((0, _concat.default)(_context = "(^|[".concat(punc, "])").concat(escapedNick, "([")).call(_context, punc, "]|$)"), 'i');
  return r.test(stylesStrippedInput);
}

/**
 * Get a query string value from the current URL
 * @param {string} _name The query string variable name
 * @param {string} _url The full URL to extract the variable from
 */
function queryStringVal(_name, _url) {
  var url = _url || window.location.href;
  var name = _lodash.default.escapeRegExp(_name);
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Convert a known error code to a human readable message
 * @param {string} err The error message from the network connection
 */
function networkErrorMessage(err) {
  var translationKey = err.replace(/^err_/, 'error_');
  var translation = TextFormatting.t(translationKey);
  if (translation === translationKey) {
    return TextFormatting.t('error_unknown');
  }
  return translation;
}

/**
 * Take a users connection object (usually from startupOptions) and normalise a connection
 * settings object. Parses websocket/direct/kiwiServer/etc options and creates a single
 * object that Kiwi can consistently read from.
 * @param {Object} config User provided connection config object
 */
function connectionInfoFromConfig(config) {
  var connection = {
    tls: false,
    port: 0,
    hostname: '',
    direct: false,
    direct_path: ''
  };
  var wsUri = config.websocket ? matchUri(config.websocket) : null;
  if (wsUri) {
    connection.direct = true;
    connection.tls = ['wss', 'https', 'ircs'].indexOf(wsUri.protocol) > -1;
    connection.port = wsUri.port;
    connection.hostname = wsUri.hostname;
    connection.direct_path = wsUri.path;
    if (wsUri.search) {
      connection.direct_path += '?' + wsUri.search;
    }
  } else {
    connection.tls = config.tls;
    connection.port = config.port;
    connection.hostname = config.server;
    connection.direct = !!config.direct;
    connection.direct_path = config.direct_path || '';
  }
  return connection;
}
function matchUri(uri) {
  var reg = /(?:([a-z]+):\/\/)?([a-z.0-9-]+)(?::(?:(\+)?([0-9]+)))?(?:\/([^?]*))?(?:\?(.*))?/;
  /*
  0: "ws://hostname.com:6676/erferf?foo=val"
  1: "ws"
  2: "hostname.com"
  3: undefined
  4: "6676"
  5: "erferf"
  6: "foo=var"
  */
  var m = uri.match(reg);
  if (!m) {
    return null;
  }
  return {
    protocol: (m[1] || '').toLowerCase(),
    hostname: m[2] || '',
    port: parseIntZero(m[4] || ''),
    path: '/' + (m[5] || ''),
    search: m[6] || ''
  };
}

/**
 * Parse a connection string into an object
 * E.g. [ircs?://]irc.network.net:[+]6667/channel?nick=mynick;
 * Multiple connections may be given, separated by ;
 * @param {string} str The connection string URI
 */
function parseIrcUri(str) {
  var reg = /(?:(ircs?|wss?):\/\/)?([a-z.0-9-]+)(?::(?:(\+)?([0-9]+)))?(?:\/([^?]*))?(?:\?(.*))?/;
  var connections = [];
  str.split(';').forEach(function (connectionString) {
    var _context2;
    if (!connectionString) {
      return;
    }
    var m = connectionString.match(reg);
    if (!m) {
      return;
    }
    var tls = m[1] === 'ircs' || m[1] === 'wss' || !!m[3];
    var direct = m[1] === 'ws' || m[1] === 'wss';
    var params = Object.create(null);
    (m[6] || '').split('&').forEach(function (p) {
      var parts = p.split('=');
      if (parts.length === 2) {
        params[parts[0].toLowerCase()] = parts[1];
      }
    });
    var channels = m[5] || params.channel || '';
    channels = (0, _map.default)(_context2 = (0, _lodash.default)(channels.split(',')).compact()).call(_context2, function (_channelName) {
      var hasPrefix = _channelName[0] === '#' || _channelName[0] === '&';
      var channelName = hasPrefix ? _channelName : '#' + _channelName;
      return channelName;
    });
    connections.push({
      tls: tls,
      server: m[2],
      port: parseInt(m[4] || (tls ? 6697 : 6667), 10),
      channels: channels,
      nick: params.nick || '',
      encoding: params.encoding || 'utf8',
      params: params,
      direct: direct
    });
  });
  return connections;
}

/**
 * Parse preset server string to an object
 * format: freenode|irc.freenode.net:+6697
 * @param {string} input Preset server string
 */
function parsePresetServer(input) {
  var ret = {
    name: '',
    server: '',
    port: 6667,
    tls: false
  };
  ret.toUri = function () {
    var _context3, _context4;
    return (0, _concat.default)(_context3 = (0, _concat.default)(_context4 = "".concat(ret.server, ":")).call(_context4, ret.tls ? '+' : '')).call(_context3, ret.port);
  };
  var val = input;
  var pipePos = val.indexOf('|');
  if (pipePos > -1) {
    ret.name = val.substr(0, pipePos);
    val = val.substr(pipePos + 1);
  }
  var colonPos = val.indexOf(':');
  if (colonPos === -1) {
    ret.server = val;
    val = '';
  } else {
    ret.server = val.substr(0, colonPos);
    val = val.substr(colonPos + 1);
  }
  if (val[0] === '+') {
    ret.tls = true;
    val = val.substr(1);
  }
  if (val.length > 0) {
    ret.port = parseInt(val, 10);
    val = '';
  }
  if (!ret.name) {
    ret.name = ret.server;
  }
  return ret;
}

/**
 * Scan though an object and extend any dot notated keys
 * @param {Object} confObj Source object to traverse
 */
function dedotObject(confObj, _place) {
  var place = _place || [];
  var regex = /\w\.\w/;
  _lodash.default.each(confObj, function (val, key) {
    var ourPlace = (0, _concat.default)(place).call(place, [key]);
    if ((0, _typeof2.default)(val) === 'object') {
      dedotObject(confObj[key], ourPlace);
      return;
    }
    if (regex.test(key)) {
      delete confObj[key];
      _lodash.default.set(confObj, ourPlace.join('.'), val);
    }
  });
}

/**
 * Replace the target object with source, while keeping the target object reference intact.
 * Delete all the properties from the target object and copy the properties from source
 * over to the target.
 * a = {one: 1, two: 2, three: 3}
 * b = {four: 4, five: 5, six: 6}
 * replaceObjectProps(a, b)
 * a.one === undefined;
 * a.six === 6;
 * @param {Object} target The target object that will be replaced
 * @param {Object} source The source object from which all properties will be copied from
 */
function replaceObjectProps(target, source) {
  (0, _keys.default)(target).forEach(function (prop) {
    return delete target[prop];
  });
  (0, _keys.default)(source).forEach(function (prop) {
    target[prop] = source[prop];
  });
}

/**
 * Create an ISO8601 formatted date
 * @param {Date} date The date object to create the time from. Defaults to the current time
 */
function dateIso(date) {
  var d = date || new Date();
  return strftimeUTC('%Y-%m-%dT%H:%M:%S.%LZ', d);
}

/**
 * Cast a string to an int, returning 0 if it fails
 * @param {String} inp The number as a string to convert to an int
 */
function parseIntZero(inp) {
  var int = parseInt(inp, 10);
  return (0, _isNan.default)(int) ? 0 : int;
}

/**
 * Prepare plugin provided object, for use as a plugin component
 * @param {Number} pluginId The number to identify this plugin object
 * @param {Component} componentOrElement The vue.js component object or html element
 * @param {Object} args Optional arguments for this plugin { title: '', props: {} }
 */
function makePluginObject(pluginId, componentOrElement) {
  var args = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var plugin = {
    id: pluginId,
    component: null,
    title: function title() {
      return '';
    },
    props: {},
    tabName: ''
  };

  // copy props from args
  plugin.props = (0, _assign.default)(plugin.props, args.props);
  plugin.tabName = args.tabName || args.title || 'plugin_tab_' + pluginId;
  if (componentOrElement instanceof Element) {
    // componentOrElement is an html element, so wrap it in a functional component

    // eslint-disable-next-line no-underscore-dangle
    if (componentOrElement.__vue__ && !window.kiwi_deprecations_vueEl) {
      window.kiwi_deprecations_vueEl = true;
      // eslint-disable-next-line no-console
      console.warn('deprecated component.$el added to plugin api, please switch to just passing the vue.js component object');
    }
    plugin.component = _PluginWrapper.default;
    plugin.props = (0, _assign.default)(plugin.props, {
      pluginElement: componentOrElement
    });
  } else if (componentOrElement instanceof Object) {
    // componentOrElement is an object, attempt to make a vue component from it
    plugin.component = _vue.default.extend(componentOrElement);
  } else {
    plugin.component = componentOrElement;
  }
  if (typeof args.title === 'string') {
    // if args.title exists wrap it in a function, that can also be a translation string
    plugin.title = function () {
      var _context5, _context6;
      return (0, _slice.default)(_context5 = args.title).call(_context5, 0, 2) === 't_' ? TextFormatting.t((0, _slice.default)(_context6 = args.title).call(_context6, 2)) : args.title;
    };
  }
  return plugin;
}

/**
 * Check for trailing close bracket and return true if no matching open bracket is found
 * @param {String} str The string to check for trailing brackets
 * @returns {Boolean}  Boolean true when trailing bracket and no matching open bracket
 */
function hasUnmatchedTrailingBracket(str) {
  var brackets = [{
    o: '(',
    c: ')'
  }, {
    o: '[',
    c: ']'
  }, {
    o: '{',
    c: '}'
  }];
  var type = (0, _find.default)(brackets).call(brackets, function (t) {
    return t.c === str[str.length - 1];
  });
  if (!type) {
    return false;
  }
  var unmatched = 0;
  for (var i = str.length - 1; i >= 0; i--) {
    if (str[i] === type.c) {
      unmatched++;
    } else if (str[i] === type.o) {
      unmatched--;
    }
  }
  return unmatched === 1;
}

// This provides a better sort for numbered nicks but does not work on ios9
var intlCollator;
if (global.Intl) {
  intlCollator = new Intl.Collator({}, {
    numeric: true
  });
}
function strCompare(a, b) {
  if (intlCollator) {
    return intlCollator.compare(a, b);
  }
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["helpers"]) window._kiwi_exports["helpers"] = {};
window._kiwi_exports["helpers"]["Misc"]
window._kiwi_exports.helpers.Misc = exports.default ? exports.default : exports;
