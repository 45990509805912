"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('form', {
    staticClass: "u-form kiwi-invitelist",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [!_vm.channelIsInviteOnly ? _c('div', {
    staticClass: "kiwi-invitelist-status"
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_public_channel')) + " "), _vm.areWeAnOp ? _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.setInviteOnly
    }
  }, [_vm._v(_vm._s(_vm.$t('invite_set_private')))]) : _vm._e()]) : _c('div', {
    staticClass: "kiwi-invitelist-status"
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_private_channel')) + " "), _vm.areWeAnOp ? _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.removeInviteOnly
    }
  }, [_vm._v(_vm._s(_vm.$t('invite_set_public')))]) : _vm._e()]), _vm.anyRegisteredUserCanJoin ? _c('span', [_vm._v(_vm._s(_vm.$t('invite_registered_only')))]) : _vm._e(), _vm.areWeAnOp ? [_c('div', {
    staticClass: "kiwi-invitelist-invite u-form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviteMaskOrAccount,
      expression: "inviteMaskOrAccount"
    }],
    staticClass: "u-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.inviteMaskOrAccount
    },
    on: {
      "keydown": _vm.inviteKeyDown,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.inviteMaskOrAccount = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "u-button u-button-secondary",
    on: {
      "click": function click($event) {
        return _vm.addInvite();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_add_invite')) + " ")])]), _vm.supportsAccounts && _vm.knownAccounts.length > 0 ? _c('auto-complete', {
    ref: "autocomplete",
    staticClass: "kiwi-invitelist-auto-complete",
    attrs: {
      "items-per-page": "5",
      "items": _vm.knownAccounts,
      "filter": _vm.inviteMaskOrAccount
    },
    on: {
      "selected": _vm.accountSelected
    }
  }) : _vm._e()] : _vm._e(), _c('a', {
    staticClass: "kiwi-invitelist-refresh",
    class: {
      'u-link': !_vm.is_refreshing && !_vm.clickUpdateTimeout
    },
    on: {
      "click": _vm.clickUpdateInvitelist
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invites_refresh')) + " ")]), _vm.inviteList.length > 0 ? _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table",
    class: {
      'kiwi-sidebar-settings-access-restricted': !_vm.areWeAnOp
    }
  }, [_c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }, [_vm._v(_vm._s(_vm.$t('invites_user')))]), _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }, [_vm._v(_vm._s(_vm.$t('invites_by')))]), _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }), _vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-sidebar-settings-access-table-header"
  }) : _vm._e(), _vm._l(_vm.sortedInviteList, function (invite) {
    return [_c('div', {
      key: 'mask' + invite.invited,
      staticClass: "kiwi-sidebar-settings-access-mask"
    }, [_vm._v(" " + _vm._s(_vm.displayMask(invite.invited)) + " ")]), _c('div', {
      key: 'who' + invite.invited,
      staticClass: "kiwi-sidebar-settings-access-who"
    }, [_vm._v(" " + _vm._s(invite.invited_by) + " ")]), _c('div', {
      key: 'when' + invite.invited,
      staticClass: "kiwi-sidebar-settings-access-when",
      attrs: {
        "title": new Date(invite.invited_at * 1000).toLocaleString({
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }
    }, [_vm._v(" " + _vm._s(new Date(invite.invited_at * 1000).toLocaleDateString({
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })) + " ")]), _vm.areWeAnOp ? _c('div', {
      key: 'actions' + invite.invited,
      staticClass: "kiwi-sidebar-settings-access-actions"
    }, [_c('i', {
      staticClass: "fa fa-trash",
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": function click($event) {
          return _vm.removeInvite(invite.invited);
        }
      }
    })]) : _vm._e()];
  })], 2) : _vm.is_refreshing ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('invites_refreshing')) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('invites_nobody')) + " ")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;