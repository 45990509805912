"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blockToHtml = blockToHtml;
exports.getEmojis = getEmojis;
exports.matchEmoji = matchEmoji;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _lodash = require("lodash");
var _state = _interopRequireDefault(require("@/libs/state"));
function matchEmoji(word) {
  var emojis = this.getEmojis(word);
  if (!emojis.length) {
    return false;
  }
  return [{
    index: 0,
    match: word,
    type: 'emoji',
    meta: {
      emoji: emojis[0].code
    }
  }];
}
function blockToHtml(block, isSingle, showEmoticons) {
  var _context, _context2, _context3, _context4;
  if (!showEmoticons) {
    return block.content;
  }
  var emojiLocation = (0, _state.default)().setting('emojiLocation');
  var emoji = block.meta.emoji;
  var classes = 'kiwi-messagelist-emoji' + (isSingle ? ' kiwi-messagelist-emoji--single' : '');
  var src = (0, _concat.default)(_context = "".concat(emojiLocation)).call(_context, emoji);
  return (0, _concat.default)(_context2 = (0, _concat.default)(_context3 = (0, _concat.default)(_context4 = "<img class=\"".concat(classes, "\" src=\"")).call(_context4, src, "\" alt=\"")).call(_context3, (0, _lodash.escape)(block.content), "\" title=\"")).call(_context2, (0, _lodash.escape)(block.content), "\" />");
}
function getEmojis(word) {
  var emojiList = (0, _state.default)().setting('emojis');
  var emojiLocation = (0, _state.default)().setting('emojiLocation');
  if (!Object.prototype.hasOwnProperty.call(emojiList, word)) {
    return [];
  }
  return [{
    ascii: word,
    code: emojiList[word],
    url: emojiLocation + emojiList[word]
    // imgProps allows setting properties of <img>
  }];
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["EmojiProvider"]
window._kiwi_exports.libs.EmojiProvider = exports.default ? exports.default : exports;
