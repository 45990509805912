"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  data: function data() {
    return {
      closing: false
    };
  },
  computed: {
    styleCommon: function styleCommon() {
      var style = {};
      var options = this.$state.settings.startupOptions;
      if (options.infoBackground && this.$state.ui.app_width <= 850) {
        style['background-image'] = "url(\"".concat(options.infoBackground, "\")");
      }
      return style;
    },
    styleInfo: function styleInfo() {
      var style = {};
      var options = this.$state.settings.startupOptions;
      if (options.infoBackground && this.$state.ui.app_width > 850) {
        style['background-image'] = "url(\"".concat(options.infoBackground, "\")");
      }
      return style;
    },
    backgroundImage: function backgroundImage() {
      return this.$state.settings.startupOptions.infoBackground || '';
    },
    infoContent: function infoContent() {
      return this.$state.settings.startupOptions.infoContent || '';
    }
  },
  methods: {
    close: function close() {
      var _this = this;
      this.closing = true;
      var startApp = function startApp(event) {
        _this.$el.removeEventListener('transitionend', startApp);
        _this.$state.persistence.watchStateForChanges();
        // Hacky to be using $parent but this component should only be used in a sepcific
        // scope within startup screens
        _this.$parent.$emit('start');
      };
      this.$el.addEventListener('transitionend', startApp, false);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["startups"]) window._kiwi_exports["components"]["startups"] = {};
window._kiwi_exports["components"]["startups"]["CommonLayout"]
window._kiwi_exports.components.startups.CommonLayout = exports.default ? exports.default : exports;
