"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "State", {
  enumerable: true,
  get: function get() {
    return _SidebarState.default;
  }
});
exports.default = void 0;
var _UserBox = _interopRequireDefault(require("@/components/UserBox"));
var _SidebarState = _interopRequireDefault(require("./SidebarState"));
var _SidebarAboutBuffer = _interopRequireDefault(require("./SidebarAboutBuffer"));
var _SidebarSectionSettings = _interopRequireDefault(require("./SidebarSectionSettings"));
var _Nicklist = _interopRequireDefault(require("./Nicklist"));
var _default = {
  components: {
    SidebarAboutBuffer: _SidebarAboutBuffer.default,
    SidebarSectionSettings: _SidebarSectionSettings.default,
    Nicklist: _Nicklist.default,
    UserBox: _UserBox.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  computed: {
    section: function section() {
      if (this.sidebarState.activeComponent) {
        return 'component';
      }
      return this.sidebarState.section();
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'sidebar.tab.show', function (tabName) {
      _this.showTab(tabName);
    });
  },
  methods: {
    showTab: function showTab(tabName) {
      this.$refs.tabs.setActiveByName(tabName);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Sidebar"]
window._kiwi_exports.components.Sidebar = exports.default ? exports.default : exports;
