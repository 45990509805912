"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var embedlyTagIncluded = false;
var _default = {
  props: ['url', 'showPin', 'iframeSandboxOptions'],
  data: function data() {
    return {
      embedlyObject: null,
      waitTimer: 0,
      waitCount: 0
    };
  },
  computed: {
    settings: function settings() {
      return this.$state.setting('embedly');
    },
    cardWidth: function cardWidth() {
      if (!this.showPin) {
        return '600px';
      }
      return this.settings.cardWidth || '400px';
    }
  },
  watch: {
    url: function url() {
      this.cleanEmbed();
      this.updateEmbed();
    }
  },
  created: function created() {
    this.updateEmbed();
  },
  beforeDestroy: function beforeDestroy() {
    this.cleanEmbed();
  },
  methods: {
    updateEmbed: function updateEmbed() {
      var _this = this;
      var checkEmbedlyAndShowCard = function checkEmbedlyAndShowCard() {
        // If the embedly function doesn't exist it's probably still loading
        // the embedly script
        if (typeof window.embedly !== 'function') {
          if (_this.waitTimer) {
            // maybe the url changed and there already is a timer
            clearTimeout(_this.waitTimer);
            _this.waitTimer = 0;
          }
          if (_this.waitCount < 300) {
            // max wait 30 seconds (30000ms)
            _this.waitCount++;
            _this.waitTimer = setTimeout(checkEmbedlyAndShowCard, 100);
          }
          return;
        }
        _this.$nextTick(function () {
          _this.embedlyObject = window.embedly('card', _this.$refs.embedlyLink);
          if (!_this.embedlyObject) {
            // embedly refused to create a card (maybe unsupported url)
            if (_this.showPin) {
              // showPin is true when its an inline embed
              _this.$emit('close');
            }
            return;
          }
          _this.embedlyObject.on('card.error', function (iframe) {
            // not sure this event will be triggered
            if (_this.showPin) {
              // showPin is true when its an inline embed
              _this.$emit('close');
            }
          });
          _this.$emit('setHeight', 'auto');
          if (_this.showPin) {
            // This is inline so set a max height
            _this.$el.style.maxHeight = (_this.settings.maxHeight || 400) + 'px';
          } else {
            // This is main media view set a relative max height
            _this.$emit('setMaxHeight', '54%');
          }
        });
      };
      if (!embedlyTagIncluded) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        var embedlyUrl = this.$state.getSetting('settings.embedly.script') || '//cdn.embedly.com/widgets/platform.js';
        script.src = embedlyUrl;
        head.appendChild(script);
        embedlyTagIncluded = true;
      }
      checkEmbedlyAndShowCard();
    },
    cleanEmbed: function cleanEmbed() {
      if (this.waitTimer) {
        clearTimeout(this.waitTimer);
        this.waitTimer = 0;
      }
      if (this.embedlyObject) {
        this.embedlyObject.remove();
        this.embedlyObject = null;
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UrlEmbed"]
window._kiwi_exports.components.UrlEmbed = exports.default ? exports.default : exports;
