"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _ChannelInfo = _interopRequireDefault(require("./ChannelInfo"));
var _ChannelBanlist = _interopRequireDefault(require("./ChannelBanlist"));
var _ChannelInvitelist = _interopRequireDefault(require("./ChannelInvitelist"));
var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));
// Helper to generate Vue's computed methods for getting/setting settings.
function generateComputedSetting(setting) {
  return {
    get: function get() {
      return this.buffer.setting(setting);
    },
    set: function set(newVal) {
      return this.buffer.setting(setting, newVal);
    }
  };
}
var _default = {
  components: {
    ChannelInfo: _ChannelInfo.default,
    ChannelBanlist: _ChannelBanlist.default,
    ChannelInvitelist: _ChannelInvitelist.default,
    BufferSettings: _BufferSettings.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      pluginUiElements: _GlobalApi.default.singleton().sideBarPlugins,
      accessTab: 'banlist'
    };
  },
  computed: {
    settingShowJoinParts: generateComputedSetting('show_joinparts'),
    settingShowTopics: generateComputedSetting('show_topics'),
    settingShowNickChanges: generateComputedSetting('show_nick_changes'),
    settingShowModeChanges: generateComputedSetting('show_mode_changes'),
    settingColouredNicklist: generateComputedSetting('coloured_nicklist'),
    settingExtraFormatting: generateComputedSetting('extra_formatting'),
    settingShareTyping: generateComputedSetting('share_typing'),
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SidebarSectionSettings"]
window._kiwi_exports.components.SidebarSectionSettings = exports.default ? exports.default : exports;
