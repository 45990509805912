"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getAvatarTransform = getAvatarTransform;
var _state = _interopRequireDefault(require("@/libs/state"));
var avatarPositions = [45, 135, 225, 315];
function getAvatarTransform() {
  var position = (0, _state.default)().setting('avatars.away_status_position');
  var angle = avatarPositions[position - 1] || 45;
  return "rotate(".concat(angle, " 50 50)");
}
var _default = {
  computed: {
    awayStatusTransform: getAvatarTransform
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UserAvatarCommon"]
window._kiwi_exports.components.UserAvatarCommon = exports.default ? exports.default : exports;
