"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// Built using https://github.com/vuejs/vue/blob/73bdf14ef5c9979dcba18ede3410515cecbe2f2f/src/platforms/web/runtime/components/transition-group.ts
var _default = {
  beforeMount: function beforeMount() {
    var _this = this;
    /* eslint-disable no-underscore-dangle */
    var update = this._update;
    this._update = function (vnode, hydrating) {
      // force removing pass
      _this.__patch__(_this._vnode, _this.kept, false,
      // hydrating
      true // removeOnly (!important, avoids unnecessary moves)
      );

      _this._vnode = _this.kept;
      update.call(_this, vnode, hydrating);
    };
    /* eslint-enable no-underscore-dangle */
  },
  render: function render(h) {
    var tag = this.tag || this.$vnode.data.tag || 'div';
    var map = Object.create(null);
    var prevChildren = this.prevChildren = this.children;
    var rawChildren = this.$slots.default || [];
    var children = this.children = [];
    for (var i = 0; i < rawChildren.length; i++) {
      var c = rawChildren[i];
      if (c.tag && c.key != null && String(c.key).indexOf('__vlist') !== 0) {
        children.push(c);
        map[c.key] = c;
      }
    }
    if (prevChildren) {
      var kept = [];
      var removed = [];
      for (var _i = 0; _i < prevChildren.length; _i++) {
        var _c = prevChildren[_i];
        if (map[_c.key]) {
          kept.push(_c);
        } else {
          removed.push(_c);
        }
      }
      this.kept = h(tag, null, kept);
    }
    return h(tag, null, children);
  }
};
exports.default = _default;