"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-statebrowser-usermenu",
    class: [_vm.is_usermenu_open ? 'kiwi-statebrowser-usermenu--open' : '']
  }, [_c('div', {
    staticClass: "kiwi-statebrowser-usermenu-avatar",
    class: [_vm.isConnected ? 'kiwi-statebrowser-usermenu-avatar--connected' : 'kiwi-statebrowser-usermenu-avatar--disconnected'],
    on: {
      "click": function click($event) {
        _vm.is_usermenu_open = !_vm.is_usermenu_open;
      }
    }
  }, [_c('UserAvatar', {
    attrs: {
      "user": _vm.getUser,
      "network": _vm.network,
      "allow-toggle": true,
      "force-show-status": true,
      "size": "large"
    }
  })], 1), _vm.is_usermenu_open ? _c('div', {
    staticClass: "kiwi-statebrowser-usermenu-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('state_remembered')) + " ")]), _c('a', {
    staticClass: "u-link",
    on: {
      "click": _vm.clickForget
    }
  }, [_vm._v(_vm._s(_vm.$t('state_forget')))]), _c('div', {
    staticClass: "kiwi-statebrowser-usermenu-close",
    on: {
      "click": function click($event) {
        _vm.is_usermenu_open = false;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    }
  })])]) : _c('div', {
    staticClass: "kiwi-statebrowser-usermenu-network"
  }, [_vm._v(" " + _vm._s(_vm.networkName) + " ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;