"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _lodash = require("lodash");
var _state = _interopRequireDefault(require("@/libs/state"));
var EmojiProvider = _interopRequireWildcard(require("@/libs/EmojiProvider"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = render;
exports.default = _default;
function render(blocks, renderEmoticons) {
  var state = (0, _state.default)();
  var showEmoticons = typeof renderEmoticons === 'undefined' ? state.setting('buffers.show_emoticons') : !!renderEmoticons;
  var retHtml = blocks.reduce(function (html, block, i) {
    // a
    var style = '';
    var classes = '';
    (0, _keys.default)(block.styles).forEach(function (s) {
      if (s === 'underline') {
        style += 'text-decoration:underline;';
      } else if (s === 'bold') {
        style += 'font-weight:bold;';
      } else if (s === 'italic') {
        style += 'font-style:italic;';
      } else if (s === 'quote') {
        classes += 'kiwi-formatting-extras-quote ';
      } else if (s === 'block') {
        classes += 'kiwi-formatting-extras-block ';
      } else if (s === 'color') {
        classes += "irc-fg-colour-".concat(block.styles[s], " ");
      } else if (s === 'background') {
        classes += "irc-bg-colour-".concat(block.styles[s], " ");
      }
    });
    var content;
    switch (block.type) {
      case 'url':
        content = linkifyUrl(block, {
          addHandle: state.setting('buffers.show_link_previews'),
          handleClass: 'fa fa-share-square u-link kiwi-messagelist-message-linkhandle'
        });
        break;
      case 'user':
        content = linkifyUser(block);
        break;
      case 'channel':
        content = linkifyChannel(block);
        break;
      case 'emoji':
        content = EmojiProvider.blockToHtml(block, blocks.length === 1, showEmoticons);
        break;
      default:
        content = (0, _lodash.escape)(block.content);
    }
    return html + buildSpan(content, classes, style);
  }, '');
  return "".concat(retHtml);
}
function linkifyUrl(block, _opts) {
  var _context;
  var opts = _opts || {};
  var nice = '';

  // Shorten the displayed URL if it's going to be too long
  if (block.content.length > 100) {
    nice = (0, _lodash.escape)(block.content.substr(0, 100)) + '...';
  } else {
    nice = (0, _lodash.escape)(block.content);
  }

  // Make the link clickable
  var href = block.meta.url.replace(/"/g, '%22');
  var out = (0, _concat.default)(_context = "<a target=\"_blank\" href=\"".concat(href, "\" rel=\"noopener noreferrer\" class=\"u-link\">")).call(_context, nice, "</a>");
  if (opts.addHandle) {
    var _context2, _context3;
    var cssClass = opts.handleClass || '';
    var content = opts.handleContent || '';
    out += (0, _concat.default)(_context2 = (0, _concat.default)(_context3 = "<a data-url=\"".concat((0, _lodash.escape)(block.meta.url), "\" class=\"")).call(_context3, cssClass, "\">")).call(_context2, content, "</a>");
  }
  return out;
}
function linkifyUser(block) {
  var escaped = (0, _lodash.escape)(block.meta.user);
  var colour = block.meta.colour;
  return "<a class=\"kiwi-nick\" data-nick=\"".concat(escaped, "\"") + (colour ? " style=\"color:".concat(colour, ";\"") : '') + ">".concat(escaped, "</a>");
}
function linkifyChannel(block) {
  var _context4;
  return (0, _concat.default)(_context4 = "<a class=\"u-link kiwi-channel\" data-channel-name=\"".concat((0, _lodash.escape)(block.meta.channel), "\">")).call(_context4, (0, _lodash.escape)(block.content), "</a>");
}
function buildSpan(content, classes, style) {
  if (style === '' && classes === '') {
    return content;
  }
  return '<span' + (style !== '' ? " style=\"".concat(style, "\"") : '') + (classes !== '' ? " class=\"".concat(classes, "\"") : '') + ">".concat(content, "</span>");
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
if(!window._kiwi_exports["libs"]["renderers"]) window._kiwi_exports["libs"]["renderers"] = {};
window._kiwi_exports["libs"]["renderers"]["Html"]
window._kiwi_exports.libs.renderers.Html = exports.default ? exports.default : exports;
