"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-selfuser kiwi-theme-bg"
  }, [!_vm.self_user_settings_open ? _c('div', {
    staticClass: "kiwi-selfuser-mask"
  }, [_c('span', {
    staticClass: "kiwi-selfuser-nick"
  }, [_c('away-status-indicator', {
    attrs: {
      "network": _vm.network,
      "user": _vm.user
    }
  }), _vm._v(" " + _vm._s(_vm.network.nick) + " "), _c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.closeSelfUser();
      }
    }
  }), _c('i', {
    staticClass: "fa fa-pencil",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.openSelfActions();
      }
    }
  }), _c('i', {
    staticClass: "fa fa-user",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.openProfile();
      }
    }
  })], 1), _c('span', {
    staticClass: "kiwi-selfuser-host"
  }, [_vm._v(" " + _vm._s(_vm.user.username) + "@" + _vm._s(_vm.user.host) + " "), _vm.modeString ? [_vm._v(" ( " + _vm._s(_vm.modeString) + " ) ")] : _vm._e()], 2), _vm.networkSupportsAway() ? _c('div', {
    staticClass: "u-form kiwi-away-checkbox-form"
  }, [_c('label', {
    staticClass: "kiwi-selfuser-away-label"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('away')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.awayStatus,
      expression: "awayStatus"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.awayStatus) ? _vm._i(_vm.awayStatus, null) > -1 : _vm.awayStatus
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.awayStatus,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.awayStatus = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.awayStatus = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.awayStatus = $$c;
        }
      }
    }
  })])]) : _vm._e()]) : _c('div', {
    staticClass: "kiwi-selfuser-actions"
  }, [_vm.error_message ? _c('div', {
    staticClass: "kiwi-selfuser-error-message"
  }, [_vm._v(_vm._s(_vm.error_message))]) : _vm._e(), _c('input-prompt', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    attrs: {
      "label": _vm.$t('enter_new_nick'),
      "block": true
    },
    on: {
      "submit": _vm.onNewNickSubmit,
      "cancel": _vm.closeNickChange
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;