"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _lodash = _interopRequireDefault(require("lodash"));
var IrcdDiffs = _interopRequireWildcard(require("@/helpers/IrcdDiffs"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var _AutoComplete = _interopRequireDefault(require("./AutoComplete"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var getInviteListSorter = function getInviteListSorter(extban) {
  if (!extban) {
    return function (a, b) {
      return Misc.strCompare(a.invited, b.invited);
    };
  }
  var extbanColon = extban + ':';
  return function (a, b) {
    var aAccount = a.invited.indexOf(extbanColon) === 0;
    var bAccount = b.invited.indexOf(extbanColon) === 0;
    if (aAccount && !bAccount) {
      return -1;
    } else if (!aAccount && bAccount) {
      return 1;
    }
    return Misc.strCompare(a.invited, b.invited);
  };
};
var _default = {
  components: {
    AutoComplete: _AutoComplete.default
  },
  props: ['buffer'],
  data: function data() {
    return {
      inviteMaskOrAccount: '',
      inviteList: [],
      is_refreshing: false,
      clickUpdateTimeout: 0
    };
  },
  computed: {
    supportsAccounts: function supportsAccounts() {
      return !!this.extban;
    },
    extban: function extban() {
      return IrcdDiffs.extbanAccount(this.buffer.getNetwork());
    },
    channelIsInviteOnly: function channelIsInviteOnly() {
      return typeof this.buffer.modes.i !== 'undefined';
    },
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    },
    sortedInviteList: function sortedInviteList() {
      var _context, _context2;
      var sorter = getInviteListSorter(this.extban);
      return (0, _sort.default)(_context = (0, _slice.default)(_context2 = this.inviteList).call(_context2)).call(_context, sorter);
    },
    inviteListAccounts: function inviteListAccounts() {
      var _context3;
      var extbanColon = this.extban + ':';
      return this.supportsAccounts ? (0, _filter.default)(_context3 = this.inviteList).call(_context3, function (i) {
        return i.invited.indexOf(extbanColon) === 0;
      }) : [];
    },
    anyRegisteredUserCanJoin: function anyRegisteredUserCanJoin() {
      if (!this.supportsAccounts) {
        return false;
      }
      if (!this.channelIsInviteOnly) {
        return false;
      }
      var extbanColon = this.extban + ':';

      // Find any invite that only consists of the extban and nothing else. Eg. '~a:'
      return this.inviteListAccounts.some(function (i) {
        return i.invited === extbanColon;
      });
    },
    knownAccounts: function knownAccounts() {
      var _context4, _context5;
      // Get an array of every account name we're aware of on the network, excluding
      // the ones we already have in our invite list
      var extbanColon = this.extban + ':';
      var accountMapper = function accountMapper(invite) {
        return invite.invited.replace(extbanColon, '');
      };
      var existingInviteAccounts = (0, _map.default)(_context4 = this.inviteListAccounts).call(_context4, accountMapper);
      var users = this.buffer.getNetwork().users;
      var accountUsers = [];
      (0, _values.default)(users).forEach(function (user) {
        if (user.account && !(0, _includes.default)(existingInviteAccounts).call(existingInviteAccounts, user.account)) {
          accountUsers.push(user);
        }
      });
      var autocompleteMapper = function autocompleteMapper(user) {
        var text = user.account;
        if (user.account !== user.nick) {
          text += " (".concat(user.nick, ")");
        }
        return {
          text: text,
          user: user
        };
      };
      return (0, _map.default)(_context5 = _lodash.default.orderBy(accountUsers, ['account', 'nick'])).call(_context5, autocompleteMapper);
    }
  },
  watch: {
    buffer: function buffer() {
      this.inviteList = [];
      this.is_refreshing = false;
      this.clickUpdateTimeout = 0;
      this.updateInvitelist();
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'irc.mode', function (event, network) {
      if (network !== _this.buffer.getNetwork()) {
        return;
      }
      if (!network.ircClient.caseCompare(event.target, _this.buffer.name)) {
        return;
      }
      event.modes.forEach(function (change) {
        if (change.mode.length !== 2 || change.mode[1] !== 'I') {
          return;
        }
        if (change.mode[0] === '+') {
          _this.inviteList.push({
            invited: change.param,
            invited_at: Math.floor(event.time / 1000),
            invited_by: event.nick,
            channel: event.target
          });
        } else if (change.mode[0] === '-') {
          var _context6;
          _this.inviteList = (0, _filter.default)(_context6 = _this.inviteList).call(_context6, function (invite) {
            return invite.invited !== change.param;
          });
        }
      });
    });
    this.updateInvitelist();
  },
  methods: {
    displayMask: function displayMask(inviteMask) {
      return inviteMask.replace(this.extban + ':', '') || this.$t('invite_any_registered');
    },
    clickUpdateInvitelist: function clickUpdateInvitelist() {
      var _this2 = this;
      if (this.clickUpdateTimeout) {
        return;
      }
      this.clickUpdateTimeout = setTimeout(function () {
        _this2.clickUpdateTimeout = 0;
      }, 4000);
      this.updateInvitelist();
    },
    updateInvitelist: function updateInvitelist() {
      var _this3 = this;
      if (this.is_refreshing || this.buffer.getNetwork().state !== 'connected') {
        return;
      }
      var channelName = this.buffer.name;
      var network = this.buffer.getNetwork();
      this.is_refreshing = true;
      this.buffer.getNetwork().ircClient.inviteList(channelName, function (event) {
        var currentNetwork = _this3.buffer.getNetwork();
        var bufferMatches = currentNetwork.ircClient.caseCompare(_this3.buffer.name, event.channel);
        if (!bufferMatches || network !== currentNetwork) {
          // The buffer or network changed since making the request
          return;
        }
        _this3.inviteList = event ? event.invites : [];
        _this3.is_refreshing = false;
      });
    },
    addInvite: function addInvite() {
      var _context7;
      var maskOrAccount = (0, _trim.default)(_context7 = this.inviteMaskOrAccount).call(_context7);
      if (!maskOrAccount) {
        return;
      }
      var ircClient = this.buffer.getNetwork().ircClient;
      var isMask = (0, _includes.default)(maskOrAccount).call(maskOrAccount, '@') || (0, _includes.default)(maskOrAccount).call(maskOrAccount, ':');
      if (this.supportsAccounts && !isMask) {
        var _context8;
        ircClient.addInvite(this.buffer.name, (0, _concat.default)(_context8 = "".concat(this.extban, ":")).call(_context8, maskOrAccount));
      } else {
        ircClient.addInvite(this.buffer.name, maskOrAccount);
      }
      this.inviteMaskOrAccount = '';
    },
    removeInvite: function removeInvite(mask) {
      var channelName = this.buffer.name;
      this.buffer.getNetwork().ircClient.removeInvite(channelName, mask);
    },
    setInviteOnly: function setInviteOnly() {
      this.buffer.getNetwork().ircClient.mode(this.buffer.name, '+i');
    },
    removeInviteOnly: function removeInviteOnly() {
      this.buffer.getNetwork().ircClient.mode(this.buffer.name, '-i');
    },
    accountSelected: function accountSelected(_value, item) {
      this.inviteMaskOrAccount = item.user.account;
    },
    inviteKeyDown: function inviteKeyDown(event) {
      if (!this.$refs.autocomplete) {
        return;
      }
      var autoComplete = this.$refs.autocomplete;
      if (event.key === 'Tab') {
        event.preventDefault();
        autoComplete.selectCurrentItem();
        return;
      }
      var selectedItem = autoComplete.selectedItem;
      if (event.key === 'Enter' && selectedItem.user.account === this.inviteMaskOrAccount) {
        event.preventDefault();
        this.addInvite();
        return;
      }
      this.$refs.autocomplete.handleOnKeyDown(event);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ChannelInvitelist"]
window._kiwi_exports.components.ChannelInvitelist = exports.default ? exports.default : exports;
